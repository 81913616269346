

















































































import Vue from 'vue'
import { faViber, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { getTelephoneCode } from '~/utils/telephone'
import PhoneService from '~/services/PhoneService'
import { mapDeps } from '~/plugins/dependency-container/utils'
import StatsService from '~/services/stats/StatsService'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { mapGetters } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'

export default Vue.extend({
  components: { CImg },
  props: {
    telephone: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: '80'
    },
    height: {
      type: String,
      required: false,
      default: '15'
    },
    fontSize: {
      type: String,
      required: false,
      default: 'auto'
    },
    invert: {
      type: Boolean,
      required: false,
      default: false
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    disableGoogleIndexing: {
      type: Boolean,
      required: false,
      default: true
    },
    showMessagingApps: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    telephoneNumberTag: {
      type: String,
      required: false,
      default: 'a'
    },
    telephoneClasses: {
      type: String,
      required: false,
      default: null
    },
    recordClickStatForClassifiedId: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({
    langCookie: 'el'
  }),
  computed: {
    ...mapDeps({
      phoneService: PhoneService,
      statsService: StatsService
    }),
    ...mapGetters(USER_NS, {
      isAdmin: 'isAdmin'
    }),
    icons: () => ({
      faViber,
      faWhatsapp
    }),
    telephoneNumberHref() {
      const { code, telephone } = this.telephone

      if (this.isAdmin) {
        return `https://www.google.gr/search?q=+${code}${telephone}`
      }

      return `tel:+${code}${telephone}`
    },
    shouldShowMessagingApps() {
      const { showMessagingApps, telephone } = this
      return (
        showMessagingApps &&
        telephone.social &&
        !this.phoneService.numberIsGreekLandlinePhone(
          telephone.telephone,
          telephone.code
        )
      )
    }
  },
  beforeMount() {
    this.langCookie = this.$cookies.get('lang')
  },
  methods: {
    handlePhoneClick() {
      if (this.recordClickStatForClassifiedId) {
        this.recordClickStat('')
      }
    },
    handleViberClick() {
      if (this.recordClickStatForClassifiedId) {
        this.recordClickStat('.viber')
      }
    },
    handleWhatsAppClick() {
      if (this.recordClickStatForClassifiedId) {
        this.recordClickStat('.whatsapp')
      }
    },
    recordClickStat(suffix: string = '') {
      this.statsService.record(`events.clsfds.telephone_click${suffix}`, [
        Number(this.recordClickStatForClassifiedId)
      ])
    },
    getTelephoneCode
  }
})
