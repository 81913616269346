
















import Vue, { PropType } from 'vue'

enum Size {
  MEDIUM = 'md',
  SMALL = 'sm'
}

export default Vue.extend({
  props: {
    error: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    size: {
      type: String as PropType<Size>,
      default: Size.MEDIUM
    },
    alt: {
      type: Boolean,
      required: false,
      default: false
    },
    center: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
