


















































































































































































import CFormMixin from '~/components/shared/configurable/mixin/CFormMixin.vue'
import CRecaptchaText from '~/components/shared/configurable/CRecaptchaText.vue'
import {
  FallbackWidgetRenderResult,
  RecaptchaFormToken
} from '~/models/recaptcha/types'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import CInternationalTelephoneInput from '~/components/shared/configurable/form/input/CInternationalTelephoneInput.vue'
import RecaptchaService from '~/services/recaptcha/RecaptchaService'
import CTextArea from '~/components/shared/configurable/form/input/CTextArea.vue'
import { HttpStatus } from '~/constants/http'
import { defineComponent } from '@nuxtjs/composition-api'

type SendStatus = 'unsent' | 'sending' | 'sent'

interface OwnData {
  recaptchaFallbackWidgetLoaded: boolean
  sendStatus?: SendStatus
}

interface ExtraFormData {
  body?: string
}

export default defineComponent({
  components: {
    CFormError,
    CInternationalTelephoneInput,
    Spinner: () => import('~/components/car/dealers/site/Spinner.vue'),
    CRecaptchaText,
    CTextArea
  },
  mixins: [CFormMixin],
  props: {
    formUrl: { type: String, required: true },
    recaptchaToken: { type: String, default: 'contact_form' },
    colConfig: {
      type: Object,
      default: () => ({
        cols: '12',
        md: '12',
        lg: '12',
        xl: '12'
      })
    },
    hideTitle: { type: Boolean, default: false },
    schemaPath: { type: String, default: 'data.schema' },
    dataPath: { type: String, default: 'data.values' },
    spinnerWithSiteColor: { type: Boolean, default: false },
    recipientId: { type: Number, default: null },
    placeholderInField: { type: Boolean, default: false },
    withoutMessage: { type: Boolean, default: false },
    successMessage: { type: String, required: false, default: '' }
  },
  data(): OwnData & ExtraFormData & Record<string, any> {
    return {
      sendStatus: 'unsent',
      body: undefined,
      getSchemaPath: this.schemaPath,
      getDataPath: this.dataPath,
      method: 'post',
      loadingMessage: '',
      recaptchaFallbackWidgetLoaded: false,
      errors: [],
      selectedDealer: null,
      setFormWithPostResponseValues: false
    }
  },
  computed: {
    url() {
      return this.formUrl
    },
    recaptchaService() {
      return this.$dep(RecaptchaService)
    },
    sent(): boolean {
      return this.sendStatus === 'sent'
    },
    sending(): boolean {
      return this.sendStatus === 'sending'
    },
    recaptchaWidgetContainerId(): string {
      return this.recaptchaService.defaultFallbackWidgetContainerId
    },
    isManager() {
      if (this.form && this.form.dealers && this.form.dealers.length > 2) {
        return true
      }
      return false
    },
    managerOptions() {
      if (this.isManager) {
        return this.form.dealers.map((d, index) => {
          return {
            text:
              index === 0 ? d.name + ' (' + this.$t('manager') + ')' : d.name,
            value: d.id
          }
        })
      }
      return null
    },
    extraFormData(): ExtraFormData {
      const { body } = this
      return { body }
    }
  },
  watch: {
    recipientId() {
      if (this.recipientId !== this.form.recipient_id) {
        this.form.recipient_id = this.recipientId
      }
    }
  },
  mounted() {
    document.onreadystatechange = async () => {
      if (document.readyState === 'complete') {
        await this.loadV3Token()
      }
    }
  },

  methods: {
    getForm() {
      return this.form
    },
    onDataGet(_) {
      if (this.isManager) {
        this.selectedDealer = this.form.dealers[0].id
        this.senderChange(this.selectedDealer)
      }
    },
    senderChange(id) {
      let userToChange = null
      this.form.sender_id = id
      if (id === null) {
        userToChange = this.form.dealers[0]
      } else {
        for (let i = 0; i < this.form.dealers.length; i++) {
          if (id === this.form.dealers[i].id) {
            userToChange = this.form.dealers[i]
            break
          }
        }
      }

      this.form.name = userToChange.name
      this.form.email = userToChange.email
      this.form.telephone = userToChange.telephone
    },
    async submitContactForm() {
      this.$emit('submit-contact-form')

      if (!this.recaptchaFallbackWidgetLoaded) {
        await this.loadV3Token()
      }
      this.sendStatus = 'sending'
      try {
        const postError = await this.post()
        if (!postError) {
          this.$emit('post-completed')
        } else if (
          postError?.response?.status === HttpStatus.INTERNAL_SERVER_ERROR
        ) {
          this.$snackbar.error(postError)
          this.$logger.captureError(postError)
          this.sendStatus = 'unsent'
          return
        }
      } catch (error) {
        this.sendStatus = 'unsent'
      }

      if (this.hasErrors) {
        this.sendStatus = 'unsent'
        return
      }

      setTimeout(() => {
        this.sendStatus = 'sent'
      }, 800)
    },
    async onError(errors) {
      if (errors.recaptcha_token && !this.recaptchaFallbackWidgetLoaded) {
        this.recaptchaFallbackWidgetLoaded = true
        await this.$nextTick()
        const {
          token
        }: FallbackWidgetRenderResult = await this.recaptchaService.renderFallbackWidget()
        this.form.recaptcha_token = {
          v: 2,
          token
        } as RecaptchaFormToken
      }
    },
    async loadV3Token() {
      let token: string
      try {
        token = await this.recaptchaService.getToken(this.recaptchaToken)
      } catch (error) {
        this.$logger.captureError(error)
        this.$snackbar.error(error)
        return
      }
      this.form.recaptcha_token = {
        v: 3,
        token
      } as RecaptchaFormToken
    }
  }
})
