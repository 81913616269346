



















































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'
import { useFormComponent } from '~/compositions/form-component'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    },
    rows: {
      type: [Number, String],
      default: 4
    },
    wrap: {
      type: String as PropType<HTMLTextAreaElement['wrap']>,
      default: 'soft'
    },
    noResize: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    helperText: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    name: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup(props, { emit, listeners }) {
    const {
      noResize,
      value,
      trim,
      hasError,
      autofocus,
      floatingLabel,
      id,
      required,
      labelClass,
      autoHeight
    } = toRefs(props)

    const { createRandomId } = useId()
    const {
      labelClasses,
      helperTextClasses,
      controlClasses
    } = useFormComponent(
      required,
      floatingLabel,
      labelClass,
      hasError,
      'textarea'
    )
    const internalValue = ref(value.value)
    const internalId = id.value || createRandomId()
    const textAreaTemplateRef = ref<HTMLTextAreaElement | null>(null)
    const calculatedHeight = ref<string | undefined>(undefined)
    watch(value, (newValue: string) => {
      internalValue.value = newValue
      calculateHeight()
    })

    const internalListeners = computed(() => {
      return {
        ...listeners,
        input: onInput,
        change: onChange
      }
    })

    function onInput(event: InputEvent) {
      emit('input', extractText(event))
    }

    function onChange(event: any) {
      emit('change', extractText(event))
    }

    function extractText(event: InputEvent) {
      const text = (event.target as HTMLTextAreaElement).value
      return trim.value ? text.trim() : text
    }

    const textAreaClasses = computed(() => {
      const c = []
      if (noResize.value) {
        c.push('tw-resize-none')
      }
      c.push(...controlClasses.value)

      if (floatingLabel.value) {
        c.push(...['tw-pt-2.5', 'placeholder:tw-text-transparent', 'tw-peer'])
      }

      return c
    })

    onMounted(() => {
      handleAutofocus()
      calculateHeight()
    })

    function calculateHeight() {
      if (autoHeight.value && process.client && textAreaTemplateRef.value) {
        const maxHeight = 250
        const scrollHeight = textAreaTemplateRef.value.scrollHeight

        let heightToUse = scrollHeight < maxHeight ? scrollHeight : maxHeight
        if (heightToUse < 42) {
          heightToUse = 42
        }
        calculatedHeight.value = internalValue.value
          ? heightToUse + 'px'
          : undefined
      }
    }

    function scrollToBottom() {
      if (textAreaTemplateRef.value) {
        textAreaTemplateRef.value.scrollTop =
          textAreaTemplateRef.value.scrollHeight
      }
    }

    function handleAutofocus() {
      if (!autofocus.value || !textAreaTemplateRef.value) {
        return
      }

      nextTick(() => {
        window.requestAnimationFrame(() => {
          textAreaTemplateRef.value!.focus()
        })
      })
    }

    return {
      internalValue,
      internalId,
      internalListeners,
      textAreaClasses,
      helperTextClasses,
      textAreaTemplateRef,
      labelClasses,
      calculatedHeight,
      scrollToBottom,
      calculateHeight
    }
  }
})
