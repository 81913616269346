import { registry, inject } from 'tsyringe'
import { clientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'

import { FallbackWidgetRenderResult } from '~/models/recaptcha/types'
import { inBrowser } from '~/utils/env'

export const googleRecaptchaV2SiteKeyToken = Symbol(
  'googleRecaptchaV2SiteKeyInjectionToken'
)
export const googleRecaptchaV3SiteKeyToken = Symbol(
  'googleRecaptchaV3SiteKeyInjectionToken'
)

@containerScoped()
@registry([
  {
    token: googleRecaptchaV2SiteKeyToken,
    useValue: process.env.GRECAPTCHA_V2_SITE_KEY
  },
  {
    token: googleRecaptchaV3SiteKeyToken,
    useValue: process.env.GRECAPTCHA_V3_SITE_KEY
  }
])
export default class RecaptchaService {
  defaultFallbackWidgetContainerId = 'recaptcha-fallback-widget-container'
  private scriptLoaded = false
  private loader: any = null

  constructor(
    @inject(googleRecaptchaV2SiteKeyToken) private v2SiteKey: string,
    @inject(googleRecaptchaV3SiteKeyToken) private v3SiteKey: string
  ) {}

  @clientOnly
  private async getRecaptcha(): Promise<any> {
    if (!this.scriptLoaded) {
      if (!this.loader) {
        this.createLoader()
      }
      await this.loader
    }
    // TODO: Type as ReCaptcha once types are fixed
    return inBrowser() ? window.grecaptcha : {}
  }

  @clientOnly
  async getToken(action: string): Promise<string> {
    if (!this.v3SiteKey) {
      return Promise.resolve('dummy')
    }
    const recaptcha = await this.getRecaptcha()

    return new Promise((resolve, reject) => {
      recaptcha.ready(() => {
        recaptcha
          .execute(this.v3SiteKey, {
            action
          })
          .then(resolve, (err: Error) =>
            reject(err || new Error('Unexpected grecaptcha error'))
          )
      })
    })
  }

  @clientOnly
  createLoader() {
    const loader = () => {
      if (!this.v3SiteKey) {
        return Promise.resolve()
      }
      return new Promise<void>((resolve, reject) => {
        const v3Script = document.createElement('script')
        v3Script.id = 'rcv3-script'
        v3Script.setAttribute(
          'src',
          `https://www.google.com/recaptcha/api.js?render=${this.v3SiteKey}`
        )
        v3Script.onload = () => {
          this.scriptLoaded = true
          resolve()
        }
        v3Script.onerror = err => reject(err)
        document.head.appendChild(v3Script)
      })
    }
    this.loader = loader()
  }

  @clientOnly
  async renderFallbackWidget(
    containerId: string = this.defaultFallbackWidgetContainerId
  ): Promise<FallbackWidgetRenderResult> {
    if (!this.v2SiteKey) {
      return Promise.resolve({
        token: 'dummy',
        widgetId: 123456
      })
    }
    const recaptcha = await this.getRecaptcha()
    return new Promise((resolve, reject) => {
      const widgetId: number = recaptcha.render(containerId, {
        sitekey: this.v2SiteKey,
        callback: (token: string) =>
          resolve({
            token,
            widgetId: getWidgetId()
          }),
        'expired-callback': () => this.reset(containerId),
        'error-callback': reject
      })

      function getWidgetId(): number {
        return widgetId
      }
    })
  }

  @clientOnly
  async reset(containerId: string = ''): Promise<void> {
    const recaptcha = await this.getRecaptcha()
    recaptcha.reset(containerId)
  }
}
