










































import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Outlet } from '~/models/dealer/site/types'
import { SET_ACTIVE_OUTLET_ID } from '~/store/modules/shared/dealers/site/mutation-types'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import OutletCardContent from './OutletCardContent.vue'
import CTabs from '~/components/shared/configurable/tab/CTabs.vue'
import CTab from '~/components/shared/configurable/tab/CTab.vue'

export default Vue.extend({
  components: {
    OutletCardContent,
    CTabs,
    CTab
  },
  computed: {
    ...mapState(DEALER_SITE_NS, {
      websiteId: 'websiteId',
      outlets: 'outlets',
      activeOutletId: 'activeOutletId'
    }),
    ...mapGetters(DEALER_SITE_NS, {
      getTemplateOption: 'getTemplateOption',
      firstOutlet: 'firstOutlet'
    }),
    activeNavItemBackgroundColor(): string {
      return '#084f8f'
    },
    outletEntries(): [string, Outlet][] {
      return [...this.outlets.entries()]
    }
  },
  methods: {
    ...mapMutations(DEALER_SITE_NS, {
      setActiveOutletId: SET_ACTIVE_OUTLET_ID
    })
  }
})
