

















import Vue, { PropType } from 'vue'
import { faViber, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { TelephoneSocial } from '~/constants/telephone/social'
import { v4 as uuidv4 } from 'uuid'

export default Vue.extend({
  props: {
    type: {
      type: String as PropType<TelephoneSocial>,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: { type: Boolean, required: true }
  },
  data() {
    return {
      id: null
    }
  },
  computed: {
    icons() {
      return {
        viber: faViber,
        whatsapp: faWhatsapp
      }
    },
    icon() {
      if (this.type === TelephoneSocial.VIBER) {
        return this.icons.viber
      } else if (this.type === TelephoneSocial.WHATSAPP) {
        return this.icons.whatsapp
      }

      return null
    },
    title() {
      if (this.type === TelephoneSocial.VIBER) {
        return 'Viber'
      } else if (this.type === TelephoneSocial.WHATSAPP) {
        return 'WhatsApp'
      }

      return null
    },
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('change')
      }
    }
  },
  mounted() {
    this.id = uuidv4()
  }
})
