

























import Vue from 'vue'
import { faDirections } from '@fortawesome/free-solid-svg-icons'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'

export default Vue.extend({
  components: { CDynamicIcon },
  props: {
    link: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    shadow: {
      type: [String, null],
      default: 'shadow-sm'
    },
    label: {
      type: String,
      default() {
        return this.$t('directions')
      }
    },
    variant: {
      type: String,
      default: 'blue-dark'
    },
    linkWithIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: null,
      default: () => faDirections
    }
  },
  data: () => ({
    icons: {
      directions: faDirections
    }
  })
})
