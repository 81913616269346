
































import {
  faFacebookSquare,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import Vue, { PropType } from 'vue'
import { SocialMediaLinks } from '~/models/dealer/types'
import { ciX } from '~/icons/source/brand/x'
import { CIcon } from '~/icons/types'

export default Vue.extend({
  props: {
    links: {
      type: Object as PropType<SocialMediaLinks>,
      default(): SocialMediaLinks {
        return {}
      }
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    icons(): Record<string, IconDefinition | CIcon> {
      return {
        faFacebookSquare,
        ciX,
        faInstagram,
        faYoutube
      }
    }
  },
  methods: {
    getColor(color: string): string {
      const defaultColor = this.color
      return defaultColor || color
    }
  }
})
