
/* eslint-disable no-console,@typescript-eslint/no-unused-vars */
import Vue from 'vue'
import { getValueByPath } from '~/utils/object'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
export default Vue.extend({
  props: {
    dataPath: { type: String, required: false, default: '' }
  },
  data() {
    return {
      formChanged: false,
      ready: true,
      isLoading: false,
      schema: {},
      form: {},
      errors: {},
      getDataPath: 'data.values',
      getSchemaPath: 'data.schema',
      method: 'post',
      json: true,
      apiAccessError: false,
      setFormWithPostResponseValues: true
    }
  },
  computed: {
    extraFormData() {
      return {}
    },
    url() {
      console.error('The url must be set')
      return null
    },
    classes() {
      const classes = {}
      for (const name in this.schema) {
        const field = this.schema[name]
        if (field.required) {
          classes[name] = ['required']
        }
      }
      return classes
    },
    hasErrors() {
      return Boolean(Object.entries(this.errors).length)
    },
    ...mapDeps({
      scrollService: ScrollService
    })
  },
  watch: {
    form() {
      this.formChanged = true
    }
  },
  created() {
    if (this.getDataPath || this.getSchemaPath) {
      this.ready = false
    }
  },
  async mounted() {
    this.getDataPath = this.dataPath || this.getDataPath
    this.url && (await this.get())
  },
  methods: {
    preprocessForm(form) {
      return form
    },
    onSuccess(form) {},
    onError(form) {},
    onResponseError(response, code) {},
    onDataGet(data) {},
    // eslint-disable-next-line handle-callback-err
    onDataError(error) {},
    onReady(data) {},
    onErrorScroll(errors) {
      setTimeout(() => {
        const firsterror = document.querySelector('.c-form-error') // fix that in the future
        const isContactFormInModal = document.querySelector('.contact-modal') // contact form appear in modal
        if (firsterror && !isContactFormInModal) {
          this.scrollService.scrollTo(firsterror, {
            easing: 'ease-in',
            offset: -200,
            duration: 500
          })
        }
      }, 100)
    },
    onSuccessScroll(form) {
      // if (this.$refs.formScrollElement) {
      //   $('html, body').animate(
      //     { scrollTop: $(this.$refs.formScrollElement).offset().top },
      //     200
      //   )
      // }
    },
    post(shouldCompleteLoading = true) {
      let formData = Vue.util.extend({}, this.form)
      formData = Object.assign(formData, this.extraFormData)
      formData = this.preprocessForm(formData)
      this.isLoading = true

      const completeLoading = () => {
        this.isLoading = false
      }

      return this.$axios({
        url: this.url,
        method: this.method,
        data: formData
      })
        .then(response => {
          shouldCompleteLoading && completeLoading()
          const data = response.data
          if (data.data.errors) {
            this.errors = data.data.errors
            this.$emit('error', this.errors)
            this.onError(this.errors)
            this.onErrorScroll(this.errors)
          } else {
            this.errors = {}
            if (this.setFormWithPostResponseValues) {
              this.form = data.data.values
            }
            this.formChanged = false
            this.$emit('success', this.form)
            this.onSuccess(this.form, data)
            this.onSuccessScroll(this.form)
          }
        })
        .catch(error => {
          shouldCompleteLoading && completeLoading()
          this.onResponseError(error, error.response.status)
          return error
        })
    },
    showDebugForm() {},
    get() {
      if (this.getDataPath || this.getSchemaPath) {
        this.isLoading = true

        this.$axios({
          url: this.url,
          method: 'get'
        })
          .then(response => {
            this.isLoading = false
            const data = response.data
            if (this.getDataPath) {
              this.form = getValueByPath(data, this.getDataPath)
            }
            if (this.getSchemaPath) {
              this.schema = getValueByPath(data, this.getSchemaPath)
            }
            this.onDataGet(data)

            this.$nextTick(() => {
              this.ready = true
              this.$emit('form-load')
              this.onReady(data)
            })
          })
          .catch(error => {
            console.error(error)
            this.isLoading = false
            this.apiAccessError = error.response.status
            this.onDataError(error)
          })
      }
    },
    submit() {
      this.post()
      this.$emit('submit')
    }
  }
})
