









import Vue from 'vue'
import CDirectionsButton from '~/components/shared/configurable/map/CDirectionsButton.vue'

export default Vue.extend({
  components: {
    CDirectionsButton
  },
  props: {
    lat: {
      type: Number,
      required: true
    },
    lon: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    label: {
      type: String,
      default: undefined
    }
  }
})
