



import { defineComponent } from '@nuxtjs/composition-api'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  i18n: defineComponentTranslations({
    recaptcha_text: {
      en:
        'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Terms of Service</a> apply.',
      el:
        'Αυτή η σελίδα προστατεύεται από το reCAPTCHA και ισχύει η <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Όροι χρήσης</a> της Google.'
    }
  })
})
