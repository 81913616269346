import { Variant } from '~/models/app/variant'

export function useTabsClasses(
  pills = false,
  vertical = false,
  condensedMobile = false
) {
  function getTabsContainerClasses() {
    if (pills) {
      return []
    }
    if (vertical) {
      return ['tw-border-r', 'tw-border-r-solid', 'tw-border-grey-300']
    }
    if (condensedMobile) {
      return [
        'lg:tw-border-b',
        'lg:tw-border-b-solid',
        'lg:tw-border-grey-300',
        'lg:tw-bg-transparent',
        'lg:tw-rounded-none',
        'lg:tw-p-0',
        'tw-bg-white',
        'tw-rounded',
        'tw-p-1'
      ]
    }
    return ['tw-border-b', 'tw-border-b-solid', 'tw-border-grey-300']
  }

  function getContainerClass() {
    if (!vertical) {
      return ['tw-flex-col']
    }
    return []
  }

  function getNavClasses() {
    if (vertical) {
      return ['tw-flex-col', 'tw-space-y-3']
    }
    return ['tw-space-x-5'] // ctab for easy debugging
  }

  function getContentClasses() {
    if (vertical) {
      if (pills) {
        return ['tw-ml-4']
      }
      return ['tw-ml-3']
    }
    return ['tw-mt-3']
  }

  function getTabClasses(
    isActive: boolean,
    isDisabled: boolean,
    condensedMobile: boolean,
    variant: string,
    isFullWith: boolean
  ) {
    // TODO: maybe clean this up later

    // TODO: add more variants/classes
    interface tabClasses {
      color: string
      afterBg: string
      afterHoverBg: string
      pillActiveBg: string
      pillActiveColor: string
    }
    function getVariantClasses(): tabClasses {
      const defaultClasses = {
        color: '!tw-text-primary-600',
        afterBg: 'after:tw-bg-primary-500',
        afterHoverBg: 'hover:after:tw-bg-primary-500',
        pillActiveBg: 'tw-bg-primary',
        pillActiveColor: '!tw-text-white'
      }

      switch (variant) {
        case Variant.PRIMARY: {
          return defaultClasses
        }
        case Variant.INFO: {
          return {
            color: '!tw-text-blue-700',
            afterBg: 'after:tw-bg-blue-600',
            afterHoverBg: 'hover:after:tw-bg-blue-600',
            pillActiveBg: 'tw-bg-sky',
            pillActiveColor: '!tw-text-white'
          }
        }
        default: {
          return defaultClasses
        }
      }
    }

    const variantClasses = getVariantClasses()

    function pillTypeTabClasses() {
      if (pills) {
        let classes = [
          // Non-active pill
          'tw-whitespace-nowrap',
          'tw-px-3',
          'tw-py-2',
          'tw-font-medium',
          'tw-rounded-md',
          'tw-text-lg',
          'tw-text-grey-600',
          'hover:tw-text-grey-900'
        ]
        if (isActive) {
          // Active pill
          classes = [
            'tw-whitespace-nowrap',
            'tw-px-3',
            'tw-py-2',
            'tw-font-medium',
            'tw-rounded-md',
            'tw-text-lg',
            variantClasses.pillActiveBg,
            variantClasses.pillActiveColor
          ]
        }
        if (isDisabled) {
          // Disabled pill
          classes = [
            'tw-px-3',
            'tw-py-2',
            'tw-font-medium',
            'tw-rounded-md',
            'tw-text-lg',
            'tw-text-grey-300',
            'tw-cursor-not-allowed'
          ]
        }
        if (isFullWith) {
          return classes.concat('tw-flex-1')
        }
        return classes
      }
    }
    function lineTypeTabClasses() {
      // Line-type tabs
      const lineTypePadding = condensedMobile
        ? 'tw-p-2'
        : 'lg:tw-py-2 lg:tw-px-2 tw-py-3 tw-px-2'

      // Line-type default tab
      let classes = [
        lineTypePadding,
        'tw-whitespace-nowrap',
        'tw-font-medium',
        'tw-text-lg',
        'tw-transition-colors',
        'tw-text-grey-600',
        'tw-text-center',
        'hover:tw-text-grey-900',
        'tw-relative',
        "after:tw-content-['']",
        'after:tw-transition-all',
        'after:tw-bg-grey-400',
        'after:tw-opacity-100',
        'after:tw-bottom-0',
        'after:tw-h-[0.1rem]',
        'after:tw-absolute',
        'after:tw-left-0',
        'after:tw-top-full',
        'after:tw--mt-[0.1rem]',
        'after:tw-w-full',
        'after:tw-scale-x-0',
        'hover:after:tw-scale-x-100',
        variantClasses.afterHoverBg
      ]
      if (vertical) {
        if (isActive) {
          classes = [
            'tw-whitespace-nowrap',
            'tw-py-2',
            'tw-px-4',
            'tw-border-r-2',
            'tw-border-r-solid',
            'tw-text-center',
            'tw-font-medium',
            'tw-text-lg',
            'tw-transition-colors',
            'tw-border-primary-500',
            variantClasses.color
          ]
        }
        if (isDisabled) {
          classes = [
            'tw-whitespace-nowrap',
            'tw-py-2',
            'tw-px-4',
            'tw-font-medium',
            'tw-text-lg',
            'tw-text-grey-300',
            'tw-cursor-not-allowed',
            'tw-border-transparent'
          ]
        }
        classes = [
          'tw-whitespace-nowrap',
          'tw-py-2',
          'tw-px-4',
          'tw-border-r-2',
          'tw-border-r-solid',
          'tw-font-medium',
          'tw-text-lg',
          'tw-transition-colors',
          'tw-text-grey-600',
          'hover:tw-text-grey-900',
          'tw-border-transparent',
          'hover:tw-border-grey-300'
        ]
      }

      if (isActive) {
        // Line-type active tab
        classes = [
          lineTypePadding,
          'tw-whitespace-nowrap',
          'tw-font-medium',
          'tw-text-lg',
          'tw-z-[2]',
          'tw-text-center',
          'tw-transition-colors',
          variantClasses.color,
          'tw-relative',
          "after:tw-content-['']",
          'after:tw-transition-all',
          variantClasses.afterBg,
          'after:tw-opacity-100',
          'after:tw-bottom-0',
          'after:tw-h-[0.1rem]',
          'after:tw-absolute',
          'after:tw-left-0',
          'after:tw-top-full',
          'after:tw--mt-[0.1rem]',
          'after:tw-w-full',
          'after:tw-scale-x-100'
        ]
      }
      // Line-type disabled tab
      if (isDisabled) {
        classes = [
          lineTypePadding,
          'tw-whitespace-nowrap',
          'tw-font-medium',
          'tw-text-lg',
          'tw-transition-colors',
          'tw-text-grey-300',
          'tw-cursor-not-allowed',
          'tw-relative'
        ]
      }
      if (isFullWith) {
        return classes.concat('tw-flex-1')
      }
      return classes
    }

    return pills ? pillTypeTabClasses() : lineTypeTabClasses()
  }

  return {
    getTabClasses,
    getTabsContainerClasses,
    getContainerClass,
    getNavClasses,
    getContentClasses
  }
}
